import { tns } from 'tiny-slider/src/tiny-slider';

let viewportWidth = window.innerWidth;

window.groupeNordik.tab = {
  openTabs: (tabCategory) => {
    document.querySelectorAll('.w-tab__tablinks').forEach(element => element.classList.remove('borderBottom'));
    document.getElementById(`${tabCategory}__tablinks`).classList.add('borderBottom');
  },
  init: (el) => {
    el.querySelector('.w-tab__tablinks').classList.add('borderBottom');
    const nestedSliders = [];
    el.querySelectorAll('.w-tab__nested').forEach(element => {
      nestedSliders.push(tns({
        container: element,
        items: 1,
        startIndex: 0,
        nested: false,
        controls: false,
        mouseDrag: false,
        touch: false,
        nav: false,
        loop: false,
        navPosition: 'bottom',
        slideBy: 1,
        disable: true,
        nested: 'inner',
        responsive: {
          620: {
            nested: 'inner',
            mouseDrag: false,
            touch: false,
          },
        },
      }));
    });

    const slider = tns({
      container: el.querySelector('.w-tab__slider'),
      navContainer: el.querySelector('.w-tab__slider_controller'),
      nav: true,
      nested: 'outer',
      items: 1,
      controls: false,
      mouseDrag: true,
      touch: true,
      autoplay: false,
      loop: false,
      disable: false,
      responsive: {
        620: {
          disable: false,
          mouseDrag: true,
          touch: true,
        },
      },
    });

    slider.events.on('newBreakpointStart', (info, eventName) => {
      nestedSliders.forEach(element => {
        element.goTo(0);
      });
    });

    slider.events.on('indexChanged', (info, eventName) => {
      const { navItems } = info;
      const currentNavItem = Array.from(navItems).find(iNavItem => iNavItem.classList.contains('tns-nav-active')).dataset.service;
      const currentNavItemElement = el.querySelector(`#${currentNavItem}__tablinks`);
      const tabHeader = el.querySelector('.w-tab__header');
      const containerWidth = tabHeader.offsetWidth;
      const navItemLeftOffset = currentNavItemElement.offsetLeft;
      const idealScrollLeft = navItemLeftOffset - (containerWidth - currentNavItemElement.offsetWidth) / 2;

      tabHeader.scrollTo({
        left: idealScrollLeft,
        behavior: 'smooth',
      });
      el.querySelectorAll('.w-tab__tablinks').forEach(element => element.classList.remove('borderBottom'));
      el.querySelector(`#${currentNavItem}__tablinks`).classList.add('borderBottom');
    });
  },
};
