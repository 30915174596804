window.groupeNordik.button = (el) => {
  // Define variables
  const ID = el.dataset.id;
  const openBtn = document.getElementById(`${ID}__acc-button`);
  const closeBtn = document.getElementById(`${ID}__acc-modal-close`);
  const closeArea = document.getElementById(`${ID}__acc-modal-backdrop`);

  const openModal = () => {
    document.getElementById(`${ID}__acc-modal`).style.display = 'block';
  };
  const closeModal = () => {
    document.getElementById(`${ID}__acc-modal`).style.display = 'none';
  };

  if (openBtn && closeBtn && closeArea) {
    // Open dialog
    openBtn.addEventListener('click', openModal);
    // Close dialog
    closeBtn.addEventListener('click', closeModal);
    closeArea.addEventListener('click', closeModal);
  }
};
