import { tns } from 'tiny-slider/src/tiny-slider';

window.groupeNordik.heroTwo = {
  init: (el) => {
    tns({
      container: el.querySelector('.w-hero-two__slider'),
      items: 1,
      autoplayButtonOutput: false,
      autoplay: true,
      controls: false,
      nav: true,
      navPosition: 'bottom',
      speed: 1800,
    });
  },
};
