window.groupeNordik.priceWidget = {
  handleMouseover: (el) => {
    const bookButton = el.querySelectorAll('.btn');

    for (const btn of bookButton) {
      if (!btn.matches(':hover')) {
        if (btn.classList.contains('btn--inverted')) {
          btn.classList.remove('btn--inverted', 'btn--inverted-dark');
          btn.classList.add('btn--outline', 'triggerFlag');
        }
      }
    }
  },

  handleMouseleave: (el) => {
    const bookButton = el.querySelectorAll('.btn');
    for (const btn of bookButton) {
      if (btn.classList.contains('triggerFlag')) {
        btn.classList.remove('btn--outline', 'triggerFlag');
        btn.classList.add('btn--inverted', 'btn--inverted-dark');
      }
    }
  },
};
