import isEmail from 'validator/lib/isEmail';
import { Recaptcha } from '../../../../src/index';

window.groupeNordik.newsletterForm = async (el) => {
  Recaptcha.loadScript();

  const ID = el.dataset.id;
  const form = el.querySelector('.w-newsletter-form__form');
  const preForm = el.querySelector('.w-newsletter-form__pre-form');
  const inputs = form.querySelectorAll('input, button');
  const formFeedback = document.getElementById(`${ID}__feedback-form`);
  const closeBtn = document.getElementById(`${ID}__close`);
  const modal = document.getElementById(`${ID}__modal`);
  const preEmail = document.getElementById(`${ID}__pre-email`);
  const centers = await apos.http.get('/api/v1/center/all', {});

  const fields = {
    firstName: {
      element: el.querySelector('.w-newsletter-form__firstName'),
      feedback: el.querySelector('.w-newsletter-form__feedback-firstName'),
      required: true,
    },
    lastName: {
      element: el.querySelector('.w-newsletter-form__lastName'),
      feedback: el.querySelector('.w-newsletter-form__feedback-lastName'),
      required: true,
    },
    email: {
      element: el.querySelector('.w-newsletter-form__email'),
      feedback: el.querySelector('.w-newsletter-form__feedback-email'),
      required: true,
    },
    fieldset: {
      element: el.querySelector('.w-newsletter-form__subscribe_wrapper'),
      feedback: el.querySelector('.w-newsletter-form__feedback-fieldset'),
      required: false,
    },
  };

  const toggleFeedback = (message = '', type = '') => {
    formFeedback.classList.value = 'alert';
    formFeedback.innerText = message;
    formFeedback.style.display = message ? 'block' : 'none';

    if (type) {
      formFeedback.classList.add(`alert-${type}`);
    }
  };

  const validateCenters = async () => {
    if (centers) {
      let isThereAnyCenter = false;
      centers.forEach((iCenter) => {
        isThereAnyCenter = document.getElementById(`${iCenter.title}`)?.checked || isThereAnyCenter;
      });
      if ((!el.dataset.centerid || el.dataset.centerid === '') && !isThereAnyCenter) {
        fields.fieldset.feedback.classList.add('validate-center');
        fields.fieldset.feedback.innerText = window.custom_i18n.newsletter_center;
        return false;
      }
      return true;
    }
    fields.fieldset.feedback.classList.add('validate-center');
    fields.fieldset.feedback.innerText = window.custom_i18n.newsletter_centerValidate;
    return false;
  };

  const validate = async () => {
    let isValid = true;

    // Reset feedbacks
    Object.values(fields).forEach((iField) => {
      if (iField.element) {
        iField.element.classList.remove('is-invalid');
        iField.feedback.innerText = '';
      }
    });
    toggleFeedback();

    // Validate required fields
    Object.values(fields).forEach((iField) => {
      if (iField.required && iField.element.value === '') {
        iField.element.classList.add('is-invalid');
        iField.feedback.innerText = window.custom_i18n.newsletter_field;
        isValid = false;
      }
    });

    // Validate email
    if (!isEmail(fields.email.element.value)) {
      fields.email.element.classList.add('is-invalid');
      fields.email.feedback.innerText = window.custom_i18n.newsletter_validateEmail;
      isValid = false;
    }

    return isValid && (await validateCenters());
  };

  const getCentersToSubscribe = async () => {
    const centersChecked = [];
    if ((!el.dataset.centerid || el.dataset.centerid === '') && centers) {
      centers.forEach((iCenter) => {
        if (document.getElementById(`${iCenter.title}`)?.checked) {
          centersChecked.push(`${iCenter.id}`);
        }
      });
      return centersChecked;
    }
    return [el.dataset.centerid];
  };

  const submit = async (recaptchaToken) => {
    window.groupeNordik.$disableElements(inputs, true);

    const centersToSubscribe = await getCentersToSubscribe();

    if (centersToSubscribe.length > 0) {
      apos.http.post(
        '/api/v1/@groupenordik/newsletter-form-widget/submit',
        {
          body: {
            recaptchaToken,
            email: fields.email.element.value,
            firstName: fields.firstName.element.value,
            lastName: fields.lastName.element.value,
            centersId: centersToSubscribe,
          },
        },
        (error, response) => {
          if (error || !response.success) {
            toggleFeedback(error ? error.message : response.error, 'danger');
          } else {
            toggleFeedback('You were successfully subscribed!', 'success');
            form.reset();
          }

          window.groupeNordik.$disableElements(inputs, false);
        },
      );
    }
  };

  const openDialog = () => {
    modal.style.display = 'block';
    fields.email.element.value = preEmail.value;
    fields.firstName.element.focus();
    const element = document.querySelector('.w-newsletter-form__sticky__content');
    element.style.backgroundColor = '#f0ded5';
  };

  const closeDialog = () => {
    modal.style.display = 'none';
    preEmail.value = fields.email.element.value;
    const element = document.querySelector('.w-newsletter-form__sticky__content');
    element.style.backgroundColor = '#eecdbe';
  };

  closeBtn.addEventListener('click', closeDialog);
  modal.querySelector('.w-newsletter-modal__backdrop').addEventListener('click', closeDialog);

  form.onsubmit = async (event) => {
    event.preventDefault();

    if (await validate()) {
      Recaptcha.execute(submit);
    }
  };

  preForm.onsubmit = (event) => {
    event.preventDefault();

    openDialog();
  };

  const $stickyButton = document.querySelector('.w-newsletter-form__sticky');
  const $closeButton = $stickyButton.querySelector('.w-newsletter-form__sticky__icon-close');

  // Check if the user has closed the sticky before
  if (!document.cookie.includes('stickyNewsletterFormClosed=true')) {
    $stickyButton.classList.add('w-newsletter-form__sticky--show');
  }

  $closeButton.addEventListener('click', (e) => {
    e.preventDefault();
    $stickyButton.classList.remove('w-newsletter-form__sticky--show');
    // Create a cookie to remember the user's choice to close the sticky for 24h
    document.cookie = 'stickyNewsletterFormClosed=true;max-age=86400;path=/';
  });

  $stickyButton.addEventListener('click', (e) => {
    // If the click is not on the close button, open the dialog
    if (!e.target.closest('.w-newsletter-form__sticky__icon-close')) {
      openDialog();
    }
  });
};
