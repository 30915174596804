import { tns } from 'tiny-slider/src/tiny-slider';
window.groupeNordik.middleFourSlider = {
  init: (el) => {
    tns({
      container: el.querySelector('.w-middleFour__slider_container'),
      controlsContainer: el.querySelector('.w-middleFour__selector'),
      items: 1,
      nav: true,
      navPosition: 'bottom',
      mode: 'gallery',
      loop: true,
      speed: 400,
      gutter: 0,
      responsive: {
        320: {
          items: 1
        },
        640: {
          items: 1
        },
        768: {
          items: 3,
          gutter: 15
        },
        992: {
          items: 4
        },
        1200: {
          items: 4
        }
      }
    });
  }
};
