/**
 * This file's exported methods could be isolated into a service
 * & an API that is used internally across the Javascript
 * considering that methods are used across many different
 * widgets
 */

const intervals = [];
// const isMobile = Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf('Mobi') > -1;

function videoProgress(player, story, callback) {
  intervals.forEach(interval => {
    clearInterval(interval);
    intervals.shift();
  });

  const interval = setInterval(function() {
    const percentage = Math.round((player.currentTime / player.duration) * 100);
    if (percentage > 0) {
      story.value = percentage;
      if (story.value >= 99) {
        clearInterval(interval);
        callback(null, true);
      }
    }
  });
  intervals.push(interval);
}

/**
 * Main execution method to render the right video
 * when interacting with the story card.
 *
 * Previous: triggered when clicking left half of the story card
 * Next: triggered when clicking right half of the story card
 * Reset: triggered when clicking next / previous at end or beginnin
 *
 */

export const storyExecution = (id, action = null) => {
  // TO DO: Change id reference to id + class selection if no collision
  const parent = document.querySelector(`#${id}__story-card`);
  const player = document.querySelector(`#${id}__story-card video`);
  const stories = document.querySelectorAll(`#${id}__story-card .story`);
  const playBtn = document.querySelector(`#${id}__story-card .play-button`);

  // Binds the events to the objects if first init
  const initialized = parent.attributes.initialized.value;

  if (initialized === 'false') {
    parent.setAttribute('initialized', 'true');
  }

  // Index of current video playing
  //  let index = Number(player.attributes.current.value);

  let index = "0";


  // Actions handler
  if (action === 'next') {
    index = Number(player.attributes.current.value);
    if (index === 2) {
      index = 0;
      player.currentTime = 0;
      player.pause();
      player.setAttribute('current', index);
      stories.forEach(story => (story.value = 0));
    } else {
      index = Number(player.attributes.current.value) + 1;
      stories[index - 1].value = 100;
    }
  } else if (action === 'previous') {
    index = Number(player.attributes.current.value)
    if (index === 0) {
      index = 2;
      player.setAttribute('current', index);
      stories[0].value = 100;
      stories[1].value = 100;
    } else {
      index = Number(player.attributes.current.value) - 1;
      stories[index + 1].value = 0;
    }
  } else if (action === 'reset') {
    index = 0;
    player.currentTime = 0;
    player.pause();
    player.setAttribute('current', index);
    playBtn.style.display = 'flex';
    playBtn.style.opacity = 100;
    playBtn.style.zIndex = 2;
    stories.forEach(story => (story.value = 0));
    return;
  } else if (action === '1') {
    index = 0;
    player.setAttribute('current', index);
    stories[0].value = 0;
    stories[1].value = 0;
    stories[2].value = 0;
  } else if (action === '2') {
    index = 1;
    player.setAttribute('current', index);
    stories[0].value = 100;
    stories[1].value = 0;
    stories[2].value = 0;
  } else if (action === '3') {
    index = 2;
    player.setAttribute('current', index);
    stories[0].value = 100;
    stories[1].value = 100;
    stories[2].value = 0;
  }


  // Defines the updated current story to be played
  const currentStory = stories[index];

  // Resets the component if at end or beginning
  if (index >= stories.length || index < 0) {
    return storyExecution(id, 'reset');
  }

  // Progress bar active togglgin
  stories.forEach(story => story.classList.remove('active'));
  currentStory.classList.add('active');

  // Dynamically sets the source of the video playing
  player.src = currentStory.attributes.url.value;
  player.setAttribute('current', index);
  player.play();

  // Triggers progress on progress bar
  videoProgress(player, currentStory, (error, result) => {
    if (error) {
      console.log('An error occured - Could not load next story');
    }
    storyExecution(id, 'next');
  });
};

export const playStories = (event, id) => {
  const parent = document.querySelector(`#${id}__story-card`);

  const player = document.querySelector(`#${id}__story-card video`);
  const stories = document.querySelectorAll(`#${id}__story-card .story`);

  const previous = document.querySelector(`#${id}__story-card .video-previous`);
  const next = document.querySelector(`#${id}__story-card .video-next`);
  const close = document.getElementById(`${id}__close`);
  const containerVid = document.getElementById(`${id}__container-vid`);

  let holdStarter = false;
  let holdActive = false;

  if (!player || !stories || !previous || !next) {
    console.log('Missing objects for full story experience');
  }

  // Binds the events on first interaction with the story card

  if (parent.attributes.initialized.value === 'false') {
    player.addEventListener('play', () => {
      event.target.style.display = 'none';
      event.target.style.opacity = 0;
      event.target.style.zIndex = '-1';

      // Get the video to open in full screen ONLY on mobile
      // if (isMobile) {
      //   if (containerVid.requestFullscreen) {
      //     containerVid.requestFullscreen();
      //     close.style.display = 'block';
      //   } else if (containerVid.webkitRequestFullscreen) { /* Safari */
      //     containerVid.webkitRequestFullscreen();
      //     close.style.display = 'block';
      //   } else if (containerVid.msRequestFullscreen) { /* IE11 */
      //     containerVid.msRequestFullscreen();
      //     close.style.display = 'block';
      //   }
      //   close.addEventListener('click', closeStories);
      // }

      // Swiping move (right/left - next/prev video, down - close video)
      parent.addEventListener('touchstart', handleTouchStart, false);
      parent.addEventListener('touchmove', handleTouchMove, false);

      let xDown = null;
      let yDown = null;

      function getTouches(evt) {
        return (
          evt.touches || evt.originalEvent.touches // browser API
        ); // jQuery
      }

      function handleTouchStart(evt) {
        // evt.preventDefault();
        // evt.stopPropagation();

        const firstTouch = getTouches(evt)[0];
        xDown = firstTouch.clientX;
        yDown = firstTouch.clientY;
      }

      function handleTouchMove(evt) {
        // evt.preventDefault();
        // evt.stopPropagation();

        if (!xDown || !yDown) {
          return;
        }

        const xUp = evt.touches[0].clientX;
        const yUp = evt.touches[0].clientY;

        const xDiff = xDown - xUp;
        const yDiff = yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
          /* most significant */
          if (xDiff > 0) {
            /* right swipe */
            nextMouseup();
          } else {
            /* left swipe */
            previousMouseup();
          }
        } else {
          if (yDiff > 0) {
            /* down swipe */
            closeStories();
          } else {
            /* up swipe */
          }
        }
        /* reset values */
        xDown = null;
        yDown = null;
      }
    });

    player.addEventListener('ended', () => {
      event.target.style.opacity = 1;

      // Get the video to exit full screen
      if (containerVid.exitFullscreen) {
        containerVid.exitFullscreen();
        storyExecution(id, 'reset');
        close.style.display = 'none';
      } else if (containerVid.webkitExitFullscreen) {
        /* Safari */
        containerVid.webkitExitFullscreen();
        storyExecution(id, 'reset');
        close.style.display = 'none';
      } else if (containerVid.msexitFullscreen) {
        /* IE11 */
        containerVid.msExitFullscreen();
        storyExecution(id, 'reset');
        close.style.display = 'none';
      }

      next.removeEventListener('mousedown', nextMousedown);
      previous.removeEventListener('mousedown', previousMousedown);
      next.removeEventListener('mouseup', nextMouseup);
      previous.removeEventListener('mouseup', previousMouseup);
      player.load();
    });

    // Event to pause the video when holding down click
    const nextMousedown = () => {
      holdStarter = setTimeout(function() {
        holdStarter = null;
        holdActive = true;
        player.pause();
      }, 100);
    };

    // Event to pause the video when holding down click
    const previousMousedown = () => {
      holdStarter = setTimeout(function() {
        holdStarter = null;
        holdActive = true;
        player.pause();
      }, 100);
    };

    // Event to play the video or going to next video
    // depending on the duration of the click / hold
    const nextMouseup = () => {
      if (holdStarter) {
        clearTimeout(holdStarter);
        storyExecution(id, 'next');
      } else if (holdActive) {
        holdActive = false;
        player.play();
      }
    };

    // Event to play the video or going to next video
    // depending on the duration of the click / hold
    const previousMouseup = () => {
      if (holdStarter) {
        clearTimeout(holdStarter);
        storyExecution(id, 'previous');
      } else if (holdActive) {
        holdActive = false;
        player.play();
      }
    };

    const closeStories = () => {
      const modal = document.querySelector('.w-videoModal');
      modal.style.display = 'none';
    };

    next.addEventListener('mousedown', nextMousedown);
    previous.addEventListener('mousedown', previousMousedown);
    next.addEventListener('mouseup', nextMouseup);
    previous.addEventListener('mouseup', previousMouseup);
  }

  next.onclick = function() {
    storyExecution(id, 'next');
  };

  previous.onclick = function() {
    storyExecution(id, 'previous');
  };


  let s = Array.from(stories);
  document.getElementById(s[0].id).onclick = function() {
    storyExecution(id, '1');
  };
  document.getElementById(s[1].id).onclick = function() {
    storyExecution(id, '2');
  };
  document.getElementById(s[2].id).onclick = function() {
    storyExecution(id, '3');
  };
  
  storyExecution(id);

};
