import isEmail from 'validator/lib/isEmail';
import Pikaday from 'pikaday';
import { Recaptcha } from '../../../../src/index';

window.groupeNordik.registerForm = (el) => {
  Recaptcha.loadScript();

  const ID = el.dataset.id;
  const inputs = el.querySelectorAll('input, button');
  const formFeedback = document.getElementById(`${ID}__feedback`);

  const fields = {
    firstName: {
      element: document.getElementById(`${ID}__firstName`),
      feedback: document.getElementById(`${ID}__firstName_feedback`),
      required: true
    },
    lastName: {
      element: document.getElementById(`${ID}__lastName`),
      feedback: document.getElementById(`${ID}__lastName_feedback`),
      required: true
    },
    email: {
      element: document.getElementById(`${ID}__email`),
      feedback: document.getElementById(`${ID}__email_feedback`),
      required: true
    },
    dob: {
      element: document.getElementById(`${ID}__dob`),
      feedback: document.getElementById(`${ID}__dob_feedback`),
      required: true
    },
    gender: {
      element: document.getElementById(`${ID}__gender`),
      feedback: document.getElementById(`${ID}__gender_feedback`),
      required: true
    },
    postalCode: {
      element: document.getElementById(`${ID}__postalCode`),
      feedback: document.getElementById(`${ID}__postalCode_feedback`)
    },
    newsletter: {
      element: document.getElementById(`${ID}__newsletter`),
      feedback: document.getElementById(`${ID}__newsletter_feedback`)
    }
  };

  const toggleFeedback = (message = '', type = '') => {
    formFeedback.classList.value = 'alert';
    formFeedback.innerText = message;
    formFeedback.style.display = message ? 'block' : 'none';

    if (type) {
      formFeedback.classList.add(`alert-${type}`);
    }
  };

  const validate = () => {
    let isValid = true;

    // Reset feedbacks
    Object.values(fields).forEach(iField => {
      iField.element.classList.remove('is-invalid');
      iField.feedback.innerText = '';
    });
    toggleFeedback();

    // Validate required fields
    Object.values(fields).forEach(iField => {
      if (iField.required && iField.element.value === '') {
        iField.element.classList.add('is-invalid');
        iField.feedback.innerText = window.custom_i18n.register_field;
        isValid = false;
      }
    });

    // Validate email
    if (!isEmail(fields.email.element.value)) {
      fields.email.element.classList.add('is-invalid');
      fields.email.feedback.innerText = window.custom_i18n.register_fieldEmail;
      isValid = false;
    }

    // Validete Gender
    if (![ 'M', 'W', 'O', 'N' ].includes(fields.gender.element.value)) {
      fields.gender.element.classList.add('is-invalid');
      fields.gender.feedback.innerText = window.custom_i18n.register_field;
      isValid = false;
    }

    return isValid;
  };

  const submit = (recaptchaToken) => {
    window.groupeNordik.$disableElements(inputs, true);

    apos.http.post(
      '/api/v1/@groupenordik/register-form-widget/submit',
      {
        body: {
          recaptchaToken,
          eventName: el.dataset.eventname,
          payload: {
            firstName: fields.firstName.element.value,
            lastName: fields.lastName.element.value,
            email: fields.email.element.value,
            dateOfBirth: fields.dob.element.value,
            gender: fields.gender.element.value,
            postalCode: fields.postalCode.element.value,
            newsletter: fields.newsletter.element.value === 'true'
          }
        }
      },
      (error, response) => {
        if (error || !response.success) {
          toggleFeedback(error ? error.message : response.error, 'danger');
        } else {
          toggleFeedback(window.custom_i18n.register_preBooked, 'success');
          el.reset();
        }

        window.groupeNordik.$disableElements(inputs, false);
      }
    );
  };

  el.onsubmit = (event) => {
    event.preventDefault();

    if (validate()) {
      Recaptcha.execute(submit);
    }
  };

  // Date picker
  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 16);

  new Pikaday({
    field: fields.dob.element,
    yearRange: [ 1910, minDate.getFullYear() ],
    maxDate: minDate,
    defaultDate: minDate,
    toString: date => new Date(date).toISOString().split('T')[0],
    parse: dateString => new Date(dateString + 'T00:00:00')
  });
};
