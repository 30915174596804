import { tns } from 'tiny-slider/src/tiny-slider';

window.groupeNordik.imagesSlider = {
  init: (el) => {
    tns({
      container: el.querySelector('.w-images-slider__slider'),
      mode: 'gallery',
      autoplay: true,
      autoHeight: false,
      autoplayButtonOutput: false,
      loop: true,
      nav: true,
      navPosition: 'bottom',
      swipeAngle: 45,
      controls: false,
      mouseDrag: true,
      speed: 800,
    });
  },
};
