window.groupeNordik.imagesGallery = {
  init: el => {
    const modal = document.getElementById('w-imageModal');
    const imageCardContainers = el.querySelectorAll('.image-card');
    const imageElementsForClick = el.querySelectorAll('.w-image-slider__container');
    const closeBtn = el.querySelectorAll('.image-card__close');
    const totalImages = document.querySelector('.image-count');
    const totalImagesCountElement = document.getElementById('total-images-count');
    const totalImagesCount = imageCardContainers.length;

    let touchStartX = null;
    let touchEndX = null;

    totalImagesCountElement.textContent = `${totalImagesCount}`;

    totalImages.addEventListener('click', () => {
      openModal(0);
    });

    document.addEventListener('keydown', event => {
      if (event.key === 'Escape') {
        closeModal();
      } else if (event.key === 'ArrowRight') {
        showNextImage();
      } else if (event.key === 'ArrowLeft') {
        showPreviousImage();
      }
    });

    modal.addEventListener('click', event => {
      if (event.target !== event.currentTarget) {
        return;
      }
      closeModal();
    });

    let currentIndex = 0;

    const openModal = index => {
      currentIndex = index;
      updateModalContent(currentIndex);
      imageCardContainers[index].classList.add('active');
      modal.classList.add('active');
      updateImageIndex();
    };

    const closeModal = () => {
      imageCardContainers.forEach(container => container.classList.remove('active'));
      modal.classList.remove('active');
    };

    const showPreviousImage = () => {
      currentIndex = (currentIndex - 1 + imageCardContainers.length) % imageCardContainers.length;
      updateModalContent(currentIndex);
      updateImageIndex();
    };

    const showNextImage = () => {
      currentIndex = (currentIndex + 1) % imageCardContainers.length;
      updateModalContent(currentIndex);
      updateImageIndex();
    };

    const updateModalContent = currentIndex => {
      imageCardContainers.forEach(container => container.classList.remove('active'));
      imageCardContainers[currentIndex].classList.add('active');
    };

    const updateImageIndex = () => {
      const imageIndexElement = document.querySelectorAll('.image-desc__index');
      imageIndexElement.forEach(element => {
        element.textContent = `${currentIndex + 1}/${imageCardContainers.length}`;
      });
    };

    imageCardContainers.forEach(container => {
      const prevBtn = container.querySelector('.prev-button');
      const nextBtn = container.querySelector('.next-button');

      prevBtn.addEventListener('click', () => showPreviousImage());
      nextBtn.addEventListener('click', () => showNextImage());
    });

    imageElementsForClick.forEach((container, index) => {
      container.addEventListener('click', () => openModal(index));
    });

    closeBtn.forEach((container, index) => {
      container.addEventListener('click', closeModal);
    });

    modal.addEventListener('touchstart', event => {
      touchStartX = event.touches[0].clientX;
    });

    modal.addEventListener('touchmove', event => {
      touchEndX = event.touches[0].clientX;
    });

    modal.addEventListener('touchend', () => {
      if (touchStartX && touchEndX) {
        const deltaX = touchEndX - touchStartX;

        if (deltaX > 50) {
          showPreviousImage();
        } else if (deltaX < -50) {
          showNextImage();
        }
      }

      touchStartX = null;
      touchEndX = null;
    });
  },
};
