
window.groupeNordik.modal = (el) => {
  const id = el.dataset.id;
  const closeBtn = document.getElementById(`${id}__close`);
  const openBtn = document.getElementById(`${id}__open`);
  const modal = document.getElementById(`${id}__container`);
  const modalBackdrop = document.getElementById('modal-backdrop');

  const toggleModal = () => {
    openBtn.addEventListener('click', () => {
      modal.style.display = 'block';
      modalBackdrop.classList.add('modal-backdrop-dark');
    });
    closeBtn.addEventListener('click', () => {
      modal.style.display = 'none';
      modalBackdrop.classList.remove('modal-backdrop-dark');
    });
  };

};
