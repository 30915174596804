import { tns } from 'tiny-slider/src/tiny-slider';

window.groupeNordik.menu = {

  init: (el) => {

    const id = el.dataset.id;
    document.querySelector('.w-menu__tablinks').classList.add('borderBottom');

    tns({
      navContainer: document.querySelector('.w-menu__customize-controls'),
      container: el.querySelector(`.${id}__body`),
      items: 1,
      mode: 'gallery',
      nav: true,
      autoplay: false,
      loop: false,
      controls: false
    });

  },

  openTabs: (sliderID) => {
    document.querySelectorAll('.w-menu__tablinks').forEach(element => element.classList.remove('borderBottom'));
    document.getElementById(`${sliderID}__tablinks`).classList.add('borderBottom');
  }
};
