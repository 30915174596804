import MultiSelect from 'multiselect-dropdown';

window.groupeNordik._onLoad.articlePage = async () => {
  if (document.getElementById('article_page_title') && !document.getElementById('topic_page_main')) {
    const dropdownCenters = document.getElementById('article_page_title__dropdown_centers');
    const dropdownTopics = document.getElementById('article_page_title__dropdown_topics');
    const listing = document.getElementById('article_page_main__listing');
    const loadMoreBtn = document.getElementById('article_page_main__load_more_btn');
    let currentPage = 0;

    const placeHolderOptionTopic = document.createElement('option');
    placeHolderOptionTopic.setAttribute('id', 'placeHolderOptionTopic');
    placeHolderOptionTopic.setAttribute('value', '');
    placeHolderOptionTopic.setAttribute('hidden', 'hidden');
    placeHolderOptionTopic.setAttribute('selected', 'selected');
    placeHolderOptionTopic.setAttribute('disabled', 'disabled');
    placeHolderOptionTopic.innerText = window.custom_i18n.article_dropdown;

    let isFirstTimeTopicSelected = true;

    const getNextPage = async () => {
      currentPage += 1;
      loadMoreBtn.disabled = true;

      const selectedCenters = dropdownCenters.selectedOptions;
      const centerIds = Object.values(selectedCenters).filter(option => option.value !== '').map((option) => {
        for (const center of centers) {
          if (center.id === parseInt(option.value, 10)) {
            return center.aposDocId;
          }
        }
        return null;
      });
      const centersQuery = centerIds.reduce((iResult, iCenterId) => `${iResult}&centerIds[]=${iCenterId}`, '');

      const selectedTopics = dropdownTopics.selectedOptions;
      const topicIds = Object.values(selectedTopics).filter(option => option.value !== '').map(option => option.value);
      const topicsQuery = topicIds.reduce((iResult, iTopicId) => `${iResult}&topicsIds[]=${iTopicId}`, '');

      const articles = await apos.http.get(`/api/v1/article/listing?limit=6&page=${currentPage}${centersQuery}${topicsQuery}`, {});

      if (articles) {
        listing.innerHTML += articles;
      } else {
        loadMoreBtn.style.visibility = 'hidden';
      }

      loadMoreBtn.disabled = false;
    };

    let searchFilteredArticlesTimer;
    const searchFilteredArticles = async () => {
      clearTimeout(searchFilteredArticlesTimer);
      searchFilteredArticlesTimer = setTimeout(async () => {
        currentPage = 0;
        listing.innerHTML = '';
        loadMoreBtn.style.visibility = 'visible';
        getNextPage();
      }, 1000);

      const selectedTopicOptions = dropdownTopics.selectedOptions;
      if (selectedTopicOptions.length > 1) {
        isFirstTimeTopicSelected = false;
        const elementTopic = document.getElementById('placeHolderOptionTopic');
        if (elementTopic) {
          elementTopic.remove();
        }
      } else if (selectedTopicOptions.length === 0) {
        dropdownTopics.appendChild(placeHolderOptionTopic);
      }
    };

    searchFilteredArticles();

    loadMoreBtn.addEventListener('click', getNextPage);

    // Center Dropdown
    const centers = await apos.http.get('/api/v1/center/all', {});

    if (centers) {

      if (isFirstTimeTopicSelected) {
        dropdownTopics.appendChild(placeHolderOptionTopic);
      }

      const multiSelectCenters = new MultiSelect(dropdownCenters);

      multiSelectCenters.element.querySelectorAll('.option input[type=checkbox]').forEach(iElement => {
        const span = document.createElement("span");
        span.classList.add('checkmark');
        iElement.after(span);
      });

      multiSelectCenters.element.querySelectorAll('.option input').forEach(iElement => {
        iElement.addEventListener('change', searchFilteredArticles);
      });
    }

    // Topic Dropdown
    const allTopics = await apos.http.get('/api/v1/topic/all', {});

    if (allTopics) {
      allTopics.forEach(iTopic => {
        const option = document.createElement('option');
        option.setAttribute('value', `${iTopic.aposDocId}`);
        option.innerText = `${iTopic.title}`;
        dropdownTopics.appendChild(option);
      });

      const multiSelectTopics = new MultiSelect(dropdownTopics);

      multiSelectTopics.element.querySelectorAll('.option input[type=checkbox]').forEach(iElement => {
        const span = document.createElement("span");
        span.classList.add('checkmark');
        iElement.after(span);
      });

      multiSelectTopics.element.querySelectorAll('.option input').forEach(iElement => {
        iElement.addEventListener('change', searchFilteredArticles);
      });
    }
  }
};

window.groupeNordik._onLoad.article = async () => {
  if (document.getElementById('article_title')) {
    const shareBtn = document.getElementById('article_title__share_btn');
    const shareMenu = document.getElementById('article_title__share_menu');

    const openShareMenu = (e) => {
      shareMenu.style.display = 'flex';
      shareBtn.removeEventListener('click', openShareMenu);
      setTimeout(() => document.addEventListener('click', closeShareMenu), 300);
    };

    const closeShareMenu = () => {
      shareMenu.style.display = 'none';
      shareBtn.addEventListener('click', openShareMenu);
      document.removeEventListener('click', closeShareMenu);
    };

    shareBtn.addEventListener('click', openShareMenu);
  }
};
