window.groupeNordik.faq = {
  init: (el) => {
    el.querySelectorAll('.w-faq__title--button').forEach(iWrapper => iWrapper.addEventListener('click', (event) => {
      const questionId = event.target.dataset.id;
      const type = event.target.dataset.type;

      if ([ 'answer', 'multiple', 'multipleCenter' ].includes(type)) {
        const itemToToggle = document.getElementById(`${questionId}__answer`);

        if (itemToToggle) {
          document.getElementById(`${questionId}__icon`).classList.toggle('opened');
          itemToToggle.style.maxHeight = itemToToggle.style.maxHeight ? null : `${itemToToggle.scrollHeight}px`;
        }
      }
    }));
  },
};
