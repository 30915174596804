import { tns } from 'tiny-slider/src/tiny-slider';
import { storyExecution } from '../../story-card-widget/views/scripts.js';

window.groupeNordik.videoSlider = {
  init: el => {
    tns({
      container: el.querySelector('.w-video-slider__slider'),
      controlsContainer: el.querySelector('.w-videoCarousel__selector'),
      swipeAngle: false,
      speed: 400,
      items: 2,
      gutter: 10,
      edgePadding: 20,
      controls: false,
      loop: false,
      autoplay: false,
      navPosition: 'bottom',
      responsive: {
        768: {
          items: 4,
          nav: false,
          controls: true,
          edgePadding: 20,
          gutter: 20,
        },
      },
    });

    const modal = el.querySelector('.w-videoModal');
    const playCard = el.querySelectorAll('.w-video-slider__card');
    const closeBtn = el.querySelectorAll('.w-videoModal__close');

    document.addEventListener('keydown', function(event) {
      if (event.key === 'Escape') {
        closeModal();
      }
    });

    modal.addEventListener('click', function(event) {
      if (event.target !== event.currentTarget) {
        return;
      }
      closeModal();
    });

    playCard.forEach(play =>
      play.addEventListener('click', function(e) {
        const id = play.attributes.story.value;
        const player = document.querySelector(`#${id}__story-card video`);
        const playBtn = document.querySelector(`#${id}__story-card .play-button`);
        player.autoplay = true;
        playBtn.click();
        playBtn.style.opacity = 0;
        playBtn.style.zIndex = '-1';
        playBtn.style.display = 'none';
        openModal(e.currentTarget.attributes.story.value);
      }),
    );
    closeBtn.forEach(close =>
      close.addEventListener('click', function(e) {
        closeModal();
      }),
    );

    function openModal(id) {
      const story = document.querySelector(`#${id}__story-card`);
      const stories = modal.querySelectorAll('.story-card');
      stories.forEach(iStory => (iStory.style.display = 'none'));
      story.style.display = 'block';
      modal.style.display = 'flex';
    }

    function closeModal() {
      modal.style.display = 'none';
    }
  },
};
