import { tns } from 'tiny-slider/src/tiny-slider';

window.groupeNordik.discover = {
  init: (el) => {
    tns({
      container: el.querySelector('.w-discover__slider'),
      navContainer: el.querySelector('.w-discover__navSlidder'),
      mode: 'gallery',
      loop: true,
      nav: true,
      navAsThumbnails: true,
      controls: false,
      speed: 800
    });
  }
};
