import { tns } from 'tiny-slider/src/tiny-slider';

window.groupeNordik.cardsSlider = (el) => {

  tns({
    items: 2,
    controls: false,
    edgePadding: 30,
    gutter: 10,
    loop: false,
    navPosition: 'bottom',
    responsive: {
      768: {
        items: 3,
        controls: false,
        gutter: 20,
        edgePadding: 0
      }
    },
    container: el.querySelector('.w-card-slider__container'),
    swipeAngle: false,
    speed: 400
  });

};
