import { tns } from 'tiny-slider/src/tiny-slider';

window.groupeNordik.enhance = {
  init: (el) => {
    tns({
      container: el.querySelector('.w-enhance__slider'),
      mode: 'carousel',
      items: 2.3,
      slideBy: 'page',
      autoplayButtonOutput: false,
      loop: false,
      gutter: 12,
      nav: true,
      navPosition: 'bottom',
      swipeAngle: false,
      controls: false,
      mouseDrag: true,
      speed: 800,
    });
  },
};
