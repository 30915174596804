import serverSideTracking from '../scripts.js';

// This part is responsible for tracking user actions when they click on links specifically.
export default {
  // Set the listener to the body to catch all the clicks instead of setting it to each link,
  // because the links are dynamic in some cases and the listener would not be set to the new generated links
  clickToOtherHostsInit() {
    document.body.addEventListener('click', (e) => {
      const link = e.target.closest(
        'a[href*="app.thermea.com"], ' +
        'a[href*="app.thermea.ca"], ' +
        'a[href*="app.lenordik.com"], ' +
        'a[href*="tribu.groupenordik.com"]',
      );
      // Prevent if the element are the "Book" and "Give" in the header/hamburger to avoid double tracking
      if (link &&
        !link.classList.contains('booking__submit-btn') &&
        link.id !== 'giving__submit'
      ) {
        e.preventDefault();
        // eslint-disable-next-line no-return-assign
        this.clickToOtherHosts(link.href).then(() => {
          window.location.href = link.href;
        });
      }
    });
  },

  // Function to track user action when the user go to ACC or Online Reservation, send metrics, and then redirect
  clickToOtherHosts: async function (url) {
    // Check if the URL is part of ACC or Online Reservation
    const allowedHosts = [
      'app.thermea.com',
      'app.thermea.ca',
      'app.lenordik.com',
      'tribu.groupenordik.com',
    ];

    if (!allowedHosts.some(host => url.includes(host))) {
      return false;
    }

    let eventType = 'click_to_acc';
    if (url.includes('tribu.groupenordik.com')) {
      eventType = 'click_to_or';
    }

    return await serverSideTracking.sendMetrics({
      event: eventType,
    });
  },
};
