/* eslint-disable */
const userData = {
  "user_data": {
    "key": "29ede3d0-2429-11ef-ba43-df32e113cb83",
    "isHost": true,
    "SourceId": 4,
    "firstName": "Adam",
    "lastName": "Test",
    "name": "Adam Test",
    "phone": "+18191234567",
    "email": "adamtest@adamtest11.com",
    "postalCode": "J1K1B8",
    "gender": "M"
  },
}

module.exports = {
  userData,
  add_to_cart: {
    "event": "add_to_cart",
    "ecommerce": {
      "currency": "CAD",
      "value": 94,
      "booking_info": "Vendredi  2024-06-14",
      "items": [
        {
          "item_id": 246,
          "item_name": "Thermal experience (spa access)",
          "price": 94,
          "quantity": 1
        }
      ],
    },
    ...userData,
  },
  begin_checkout: {
    "event": "begin_checkout",
    "ecommerce": {
      "currency": "CAD",
      "value": 94,
      "items": [
        {
          "item_id": 246,
          "item_name": "Thermal experience (spa access)",
          "price": 94,
          "quantity": 1
        }
      ],
      "booking_info": "Vendredi  2024-06-14"
    },
    ...userData,
  },
  add_payment_info: {
    "event": "add_payment_info",
    "ecommerce": {
      "currency": "CAD",
      "value": 0,
      "items": {
        "246": {
          "item_id": 246,
          "item_name": "Thermal experience (spa access)",
          "price": 94,
          "quantity": 1
        }
      },
      "booking_info": "Vendredi  2024-06-14 10:00"
    },
    ...userData,
  }
};
