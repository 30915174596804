import { tns } from 'tiny-slider/src/tiny-slider';

window.groupeNordik.reviewCarousel = () => {
  tns({
    container: '.w-review-carousel__review-wrapper',
    rewind: true,
    items: 3,
    slideBy: 'page',
    nav: true,
    navPosition: 'bottom',
    autoplay: true,
    speed: 400,
    autoplayButtonOutput: false,
    mouseDrag: true,
    lazyload: true,
    position: 'center',
    controlsContainer: '.controls',
    responsive: {
      320: {
        items: 1,
        gutter: 24,
        edgePadding: 23,
      },
      640: {
        items: 1,
      },

      768: {
        items: 1,
        gutter: 40,
        edgePadding: 39,
      },
      992: {
        items: 2,
      },
      1200: {
        items: 3,
      },
    },

  });

};
