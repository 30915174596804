const lunr = require('lunr');

class FAQSearch {
  constructor(allSections, allQuestions) {
    this.allSections = allSections;
    this.allQuestions = allQuestions;
    this.lunr = lunr(function() {
      this.ref('id');
      this.field('tags');
      this.field('question');
      this.field('answer');

      window.lunrFAQ.forEach(function (doc) {
        this.add(doc);
      }, this);
    });
  }

  reset() {
    this.allQuestions.forEach(iQuestion => {
      iQuestion.classList.remove('hidden');
    });

    this.allSections.forEach(iSection => {
      iSection.style.display = 'block';
    });
  }

  search(searchTerm) {
    const results = this.lunr.search(searchTerm);
    return results.map(iResult => iResult.ref);
  }

  showResults(questionIds) {
    this.allQuestions.forEach(iQuestion => {
      if (questionIds.includes(iQuestion.dataset.id)) {
        iQuestion.classList.remove('hidden');
      } else {
        iQuestion.classList.add('hidden');
      }
    });

    this.allSections.forEach(iSection => {
      iSection.style.display = iSection.querySelector('.w-faq__question:not(.hidden)') ? 'block' : 'none';
    });
  }
}

window.groupeNordik.navFaq = {
  init: (el) => {
    // Sticky menu
    const navBar = el.querySelector('.w-nav-faq__faq-dropdown');
    window.addEventListener('scroll', () => {
      const bookingHeigth = document.getElementById('header-booking').getBoundingClientRect().height;
      const navigationHeigth = document.getElementById('nav-bar').getBoundingClientRect().height;
      const faqNavBarHeight = navBar.getBoundingClientRect().height;

      if (window.scrollY >= 500) {
        if (window.innerWidth <= 991 && window.innerWidth >= 768) {
          navBar.style.top = (faqNavBarHeight + 10) + 'px';
          navBar.classList.add('w-nav-faq__faq-dropdown--scrolled');
        } else if (window.innerWidth <= 768) {
          navBar.style.top = (bookingHeigth + navigationHeigth + faqNavBarHeight - 10) + 'px';
          navBar.classList.add('w-nav-faq__faq-dropdown--scrolled');
        } else {
          navBar.style.top = (faqNavBarHeight) + 'px';
          navBar.classList.add('w-nav-faq__faq-dropdown--scrolled');
        }
      } else {
        navBar.classList.remove('w-nav-faq__faq-dropdown--scrolled');
      }
    });

    // Search bar
    const form = el.querySelector('.w-nav-faq__search form');
    const textInput = el.querySelector('input[type="text"]');
    const submitBtn = form.querySelector('button[type="submit"]');
    const resetBtn = form.querySelector('button[type="reset"]');

    const allSections = document.querySelectorAll('.w-nav-faq__flex-faq');
    const allQuestions = document.querySelectorAll('.w-faq__question');
    const faqSearch = new FAQSearch(allSections, allQuestions);

    const toggleSearchButtons = (showSearch) => {
      if (showSearch) {
        submitBtn.style.display = 'inline-block';
        resetBtn.style.display = 'none';
      } else {
        submitBtn.style.display = 'none';
        resetBtn.style.display = 'inline-block';
      }
    };

    form.onsubmit = (event) => {
      event.preventDefault();

      const searchTerm = textInput.value;

      if (searchTerm) {
        toggleSearchButtons(false);
        const questionIds = faqSearch.search(searchTerm);
        faqSearch.showResults(questionIds);
      } else {
        toggleSearchButtons(true);
        faqSearch.reset();
      }
    };

    form.onreset = () => {
      toggleSearchButtons(true);
      faqSearch.reset();
    };

    let lastInput = '';
    textInput.addEventListener('keyup', (event) => {
      if (event.target.value !== lastInput) {
        toggleSearchButtons(true);
      }

      lastInput = event.target.value;
    });
  },

  scrollTo: (scrollToId) => {
    const faqDropdown = document.getElementById('w-nav-faq__faqCategoryDropdown');
    const currentOption = faqDropdown.value;

    const elemntToToggle = document.getElementById(scrollToId || currentOption);
    const y = elemntToToggle.getBoundingClientRect().top + window.scrollY - 230;
    window.scroll({
      top: y,
      behavior: 'smooth',
    });
  },
};
