import { tns } from 'tiny-slider/src/tiny-slider';

window.groupeNordik.verticalPictures = {
  init: (el) => {
    tns({
      container: el.querySelector('.w-texts-pictures__slider'),
      mode: 'gallery',
      autoplay: true,
      autoplayButtonOutput: false,
      loop: true,
      nav: true,
      navPosition: 'bottom',
      swipeAngle: 45,
      controls: false,
      mouseDrag: true,
      speed: 800,
    });
  },
};
