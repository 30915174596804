import { tns } from 'tiny-slider/src/tiny-slider';

window.groupeNordik.oneSideSlider = {
  init: (el) => {
    tns({
      container: el.querySelector('.w-one__slider'),
      items: 1,
      mode: 'gallery',
      autoplayButtonOutput: false,
      controls: false,
      nav: false,
      mouseDrag: true,
      autoplay: true,
      speed: 1800
    });
  }
};
