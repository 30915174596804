import { Loader } from '@googlemaps/js-api-loader';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

window.groupeNordik.nordikMap = {
  init: (el) => {
    const loader = new Loader({
      apiKey: 'AIzaSyD7u2p1IwvEp-bwHEV53FSbCcajZcFs97Q',
    });
    loader.load().then(() => {
      const map = new google.maps.Map(el.querySelector('.w-nordik-map__map'), {
        zoom: 6,
        center: {
          lat: 46.964973678068844,
          lng: -85.05525276760292,
        },
        disableDefaultUI: true,
        keyboardShortcuts: false,
        styles: [
          {
            featureType: 'administrative',
            elementType: 'all',
            stylers: [
              {
                visibility: 'on',
              },
              {
                lightness: 33,
              },
            ],
          },
          {
            featureType: 'administrative.province',
            elementType: 'labels',
            stylers: [
              {
                visibility: 'simplified',
              },
            ],
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [
              {
                color: '#c5dac6',
              },
            ],
          },
          {
            featureType: 'poi.park',
            elementType: 'labels',
            stylers: [
              {
                visibility: 'on',
              },
              {
                lightness: 20,
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'all',
            stylers: [
              {
                lightness: 20,
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [
              {
                color: '#c5c6c6',
              },
            ],
          },
          {
            featureType: 'road.arterial',
            elementType: 'geometry',
            stylers: [
              {
                color: '#e4d7c6',
              },
            ],
          },
          {
            featureType: 'road.local',
            elementType: 'geometry',
            stylers: [
              {
                color: '#fbfaf7',
              },
            ],
          },
        ],
      });
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        map.setZoom(4);
      }

      const locations = [ {
        relativePosition: {
          lat: 45.696194530691315,
          lng: -75.7010689604097,
        },
        position: {
          lat: 45.505096383777655,
          lng: -75.80273300165047,
        },
        url: 'https://www.google.com/maps/place/Nordik+Spa-Nature+%7C+Chelsea/@45.5049385,-75.8049217,17z/data=!4m5!3m4!1s0x4cce1d7c6539a4a3:0xaeaff7340f0ac503!8m2!3d45.5049385!4d-75.802733',
        label: ' Nordik Spa-Nature',
        iconUrl: 'images/house_primary-500.svg',
      }, {
        relativePosition: {
          lat: 43.94287643056768,
          lng: -78.98001006581322,
        },
        position: {
          lat: 43.91320046854794,
          lng: -78.96717584204507,
        },
        url: 'https://www.google.com/maps/place/Therm%C3%ABa+spa+village+%7C+Whitby/@43.9144815,-78.9686047,17z/data=!4m5!3m4!1s0x89d51f50daed62e5:0x72bfc8be789b2a47!8m2!3d43.9131336!4d-78.9671524',
        label: 'Thermëa spa village',
        iconUrl: 'images/house_primary-500.svg',
      }, {
        relativePosition: {
          lat: 50.17314104669193,
          lng: -97.16370147341091,
        },
        position: {
          lat: 49.833837904117416,
          lng: -97.1396500015294,
        },
        url: 'https://www.google.com/maps/place/Therm%C3%ABa+by+Nordik+Spa-Nature/@49.833658,-97.1418387,17z/data=!4m12!1m6!3m5!1s0x52ea75d10cdda807:0x47b6175d9bdaee30!2sTherm%C3%ABa+by+Nordik+Spa-Nature!8m2!3d49.8336534!4d-97.1396871!3m4!1s0x52ea75d10cdda807:0x47b6175d9bdaee30!8m2!3d49.8336534!4d-97.1396871',
        label: 'Thermëa by Nordik Spa-Nature',
        iconUrl: 'images/house_primary-500.svg',
      },
      {
        relativePosition: {
          lat: 45.69235676526239,
          lng: -75.78286289964065,
        },
        position: {
          lat: 45.47674165031449,
          lng: -75.77753766916,
        },
        url: 'https://www.google.com/maps/place/Groupe+Nordik/@45.4765988,-75.779096,17z/data=!3m1!4b1!4m5!3m4!1s0x4cce1d2eb2c9a3f7:0x13d2ef2f61297e59!8m2!3d45.4765988!4d-75.7775913',
        label: 'Groupe Nordik',
        iconUrl: 'images/house_primary-500.svg',
      } ];

      const markers = locations.map((iElement) => {
        const marker = new google.maps.Marker({
          position: iElement.relativePosition,
          icon: {
            url: iElement.iconUrl,
            scaledSize: new google.maps.Size(30, 30),
          },
          url: iElement.url,
          map: map,
        });
        marker.addListener('click', () => {
          window.open(marker.url, '_blank');
        });
        return marker;
      });

      const markerCluster = new MarkerClusterer({
        markers,
        map,
      });

      markerCluster.addListener('click', () => {
        map.setZoom(12);
      });

      function changeMarkerPosition(relative) {
        for (let i = 0; i < markers.length; i++) {
          markers[i].setPosition(relative ? locations[i].relativePosition : locations[i].position);
        }
        if (!relative) {
          markerCluster.map = map;
        }
      }

      map.addListener('zoom_changed', () => {
        if (map.getZoom() > 7.5) {
          changeMarkerPosition(false);
        } else {
          changeMarkerPosition(true);
        }
      });
    });
  },
};
