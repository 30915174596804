import VimeoPlayer from '@vimeo/player';

window.groupeNordik.hero = (el) => {
  const vimeoId = el.dataset.vimeoid;

  if (vimeoId) {
    new VimeoPlayer(el.querySelector('.w-hero__vimeo'), {
      id: vimeoId,
      width: 720,
      quality: '720p',
      muted: true,
      background: true
    });
  }
};
