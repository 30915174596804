import { tns } from 'tiny-slider/src/tiny-slider';

window.groupeNordik.experienceSlider = {
  init: (el) => {
    tns({
      container: el.querySelector('.w-experience__slider'),
      items: 1,
      slideBy: 'page',
      autoWidth: true,
      autoplay: false,
      loop: true,
      mouseDrag: true,
      autoplayButtonOutput: false,
      controls: false,
      gutter: 10,
      navPosition: 'bottom',
      speed: 600,
      edgePadding: 0,
      responsive: {
        768: {
          edgePadding: 20,
          items: 1,
          center: true,
          slideBy: 1,
          gutter: 10,
        },
      },
    });
  },
};
