window.groupeNordik.pressArticle = {
  showMore: () => {
    const hiddenCards = document.querySelectorAll('.w-press-article__inner');
    hiddenCards.forEach(card => {
      card.classList.remove('hidden');
    });
    if (document.querySelectorAll('.w-press-article__inner.hidden')) {
      document.querySelector('.w-press-article__show-more').style.display = 'none';
    }
  },
};
