window.groupeNordik.massagePrice = {

  expendCards: (id) => {
    const showBtn = document.getElementById(`${id}--show`);
    const arrowIcon = document.getElementById(`${id}--arrow-icon`);
    const card = document.getElementById(`${id}__card`);
    const premium = document.getElementById(`${id}__premium`);

    if (showBtn.innerHTML === 'Show Price') { // OPEN
      showBtn.innerHTML = 'Hide Price';
      arrowIcon.classList.add('rotate');
      card.classList.add('profile--expanded');
      card.classList.remove('profile--unexpanded');
      card.classList.add('set--open');
      card.classList.remove('set--close');
      premium.classList.add('set--open');
      premium.classList.remove('set--close');
    } else { // CLOSE
      showBtn.innerHTML = 'Show Price';
      arrowIcon.classList.remove('rotate');
      arrowIcon.classList.add('rotate-inverse');
      card.classList.add('profile--unexpanded');
      card.classList.remove('profile--expanded');
      card.classList.add('set--close');
      card.classList.remove('set--open');
      premium.classList.add('set--close');
      premium.classList.remove('set--open');
    }
  },
};
