import { tns } from 'tiny-slider/src/tiny-slider';
window.groupeNordik.middleSlider = {
  init: (el) => {
    tns({
      container: el.querySelector('.w-middle__slider_container'),
      controlsContainer: el.querySelector('.w-middle__selector'),
      items: 1,
      nav: true,
      navPosition: 'bottom',
      mode: 'gallery',
      loop: true,
      speed: 400,
      responsive: {
        320: {
          items: 1,
        },
        640: {
          items: 1,
        },
        768: {
          items: 2,
          gutter: 10,
        },
        992: {
          items: 2,
        },
        1440: {
          items: 3,
        },
      },
    });
  },
};
