import { tns } from 'tiny-slider/src/tiny-slider';

window.groupeNordik.carousel = (el) => {
  tns({
    container: el.querySelector('.w-carousel__slider'),
    controlsContainer: el.querySelector('.w-carousel__controls'),
    items: 1,
    autoplay: false,
    mouseDrag: true,
    nav: false,
    gutter: 5,
    edgePadding: 30,
    responsive: {
      576: {
        edgePadding: 75
      },
      992: {
        edgePadding: 150
      }
    }
  });
};
